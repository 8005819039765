<template>
    <div v-if="loading" class="text-center">
        <v-progress-circular indeterminate color="red-darken-1"></v-progress-circular>
    </div>
    <div class="orange-darken-1"><span class="text-h4 ">Season Standings</span></div>
    <v-sheet :elevation="4"
             border
             rounded
             v-if="standings"
             style="min-width:500px">
        <v-table v-if="standings.length > 0">
            <thead>
                <tr>
                    <th class="text-left"><strong>#</strong></th>
                    <th class="text-center"><strong>Driver</strong></th>
                    <th></th>
                    <th class="text-left"><strong>Car #</strong></th>
                    <th class="text-left"><strong>Points</strong></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="standing in standings" :key="standing.position">
                    <td><b>{{ standing.position }}</b></td>
                    <td>
                        <div>
                            <p>{{ standing.driver }}  <span :class="`${ standing.countryCode }`"></span></p>
                            <p class="text-medium-emphasis">{{ standing.team }}</p>
                        </div>
                    </td>
                    <td><v-avatar :image="`${ standing.driverImage }`"></v-avatar></td>
                    <td><v-chip variant="outlined" size="small">{{ standing.carNumber }}</v-chip></td>
                    <td class="font-weight-medium">{{ standing.points }}</td>
                </tr>
            </tbody>
        </v-table>
        <span v-else class="text-h5">No Data</span>
    </v-sheet>

</template>

<style scoped>
    .v-progress-circular {
        margin: 1rem;
    }
</style>

<script lang="js">
    import { defineComponent } from 'vue';

    export default defineComponent({
        props: { selectedyear: Number },
        data() {
            return {
                loading: false,
                standings: null,
            };
        },
        components: {
        },
        created() {
            // fetch the data when the view is created and the data is
            // already being observed
            this.fetchData();
        },
        watch: {
            // call again the method if the route changes
            '$route': 'fetchData',
            'selectedyear': 'fetchData'
        },
        methods: {
            fetchData() {
                this.standings = null;
                this.loading = true;

                fetch('/api/driverstats/driverstandings/' + this.selectedyear)
                    .then(r => r.json())
                    .then(json => {
                        this.standings = json;
                        this.loading = false;
                        return;
                    });
            }
        },
    });
</script>