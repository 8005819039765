<template>
    <span class="text-h4">Circuits - {{ selectedyear }}</span>
    <div v-if="loading" class="loading">
        <v-progress-circular indeterminate color="red-darken-1"></v-progress-circular>
    </div>
    <v-expansion-panels 
                        variant="accordion" 
                        multiple 
                        v-if="seasoncircuits" 
                        style="min-width:500px"
                        v-model="panel">
        <v-expansion-panel v-for="(track, index) in seasoncircuits" :key="track.raceId" :value="`${track.raceId}`">
            <v-expansion-panel-title>
                <v-row align="center">
                    <v-col>#{{ index + 1 }} - <b>{{ track.trackName }}</b></v-col>
                    <v-col>
                        {{ track.date }}
                        <v-icon v-if="track.sprintWeekend" 
                                icon="mdi-alpha-s-circle-outline" 
                                color="light-green-darken-4"
                                class="ml-3"
                                title="Sprint weekend"></v-icon>
                    </v-col>
                    <v-col>
                        <div v-if="track.status === 'Completed'">
                            <v-chip size="small" variant="elevated" color="green-darken-1">{{ track.status }}</v-chip>
                        </div>
                        <div v-else-if="track.status === 'Cancelled' || track.status === 'Canceled'">
                            <v-chip size="small" variant="elevated" color="deep-orange-darken-2">{{ track.status }}</v-chip>
                        </div>
                        <div v-else-if="track.status === 'Scheduled'">
                            <v-chip size="small" variant="elevated" color="brown-darken-1">{{ track.status }}</v-chip>
                        </div>
                        <div v-else-if="track.status === 'Postponed'">
                            <v-chip size="small" variant="elevated" color="blue-darken-1">{{ track.status }}</v-chip>
                        </div>
                    </v-col>
                </v-row>
            </v-expansion-panel-title>
            <v-expansion-panel-text class="bg-blue-grey-darken-1">
                <v-row>
                    <v-col> <!-- Circuit details -->
                        <v-card class="mx-auto" min-width="365">
                            <v-card-title>{{ track.trackName }} <v-icon icon="mdi-go-kart-track" color="blue-grey"></v-icon></v-card-title>
                            <v-card-subtitle class="pt-4">
                                {{ track.trackCountry }} - {{ track.trackCity }}
                            </v-card-subtitle>
                            <v-card-text>
                                <v-img class="align-end" height="150"
                                       :src="`${ track.trackImage }`">
                                </v-img>
                                <v-divider style="margin: 3px 0 3px 0"></v-divider>
                                <v-row dense>
                                    <v-col class="font-weight-medium">Length</v-col>
                                    <v-col>{{ track.circuitDetails.trackLength }}</v-col>
                                </v-row>
                                <v-row dense>
                                    <v-col class="font-weight-medium">Distance</v-col>
                                    <v-col>{{ track.circuitDetails.raceDistance }}</v-col>
                                </v-row>
                                <v-row dense>
                                    <v-col class="font-weight-medium">Laps</v-col>
                                    <v-col>{{ track.circuitDetails.laps }}</v-col>
                                </v-row>
                                <v-row dense>
                                    <v-col class="font-weight-medium">First GP</v-col>
                                    <v-col>{{ track.circuitDetails.firstGrandPrix }}</v-col>
                                </v-row>
                                <div v-if="track.circuitDetails.circuitLapRecordDriverName">
                                    <v-divider style="margin: 3px 0 3px 0"></v-divider>
                                    <v-row dense>
                                        <v-col class="font-weight-medium">Lap record</v-col>
                                        <v-col>{{ track.circuitDetails.circuitLapRecordTime }}</v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col class="font-weight-medium">Driver</v-col>
                                        <v-col>{{ track.circuitDetails.circuitLapRecordDriverName }}</v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col class="font-weight-medium">Year</v-col>
                                        <v-col>{{ track.circuitDetails.circuitLapRecordYear }}</v-col>
                                    </v-row>
                                </div>
                            </v-card-text>
                        </v-card>   
                    </v-col>
                    <v-col v-if="track.status === 'Completed'"> <!-- Won by -->
                        <v-card class="mx-auto" min-width="365">
                            <v-card-title>Won by <v-icon icon="mdi-trophy-outline" color="amber-darken-3"></v-icon></v-card-title>
                            <v-avatar size="120" :image="`${ track.finishingGrid[0].driverDetails.image }`"></v-avatar>
                            <v-card-subtitle class="pt-4">
                                {{ track.finishingGrid[0].driverDetails.name }} ({{ track.finishingGrid[0].driverDetails.abbr }})
                            </v-card-subtitle>
                            <v-card-text>
                                <v-divider style="margin: 3px 0 3px 0"></v-divider>
                                <v-row dense>
                                    <v-col class="font-weight-medium">Race time</v-col>
                                    <v-col>{{ track.finishingGrid[0].time }}</v-col>
                                </v-row>
                                <v-row dense>
                                    <v-col class="font-weight-medium">Grid start</v-col>
                                    <v-col>{{ positionString(track.finishingGrid[0].grid) }}</v-col>
                                </v-row>
                                <v-row dense>
                                    <v-col class="font-weight-medium">Team</v-col>
                                    <v-col>{{ track.finishingGrid[0].circuitTeam.name }}</v-col>
                                </v-row>
                                <v-divider style="margin: 3px 0 3px 0"></v-divider>
                                <div v-if="track.circuitFastestLapDetails.driverId">
                                    <v-row>
                                        <v-col class="font-weight-medium text-h6">
                                            Fastest Lap <v-icon icon="mdi-clock-fast" color="deep-orange"></v-icon>
                                            <v-avatar :image="`${ track.circuitFastestLapDetails.driverDetails.image }`"></v-avatar>
                                        </v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col class="font-weight-medium">Driver</v-col>
                                        <v-col>{{ track.circuitFastestLapDetails.driverDetails.name }} - ({{ track.circuitFastestLapDetails.driverDetails.abbr }})</v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col class="font-weight-medium">Lap time</v-col>
                                        <v-col>{{ track.circuitFastestLapDetails.time }}</v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col class="font-weight-medium">Avg speed</v-col>
                                        <v-col>{{ track.circuitFastestLapDetails.averageSpeed }}</v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col class="font-weight-medium">Team</v-col>
                                        <v-col>{{ track.circuitFastestLapDetails.teamName }}</v-col>
                                    </v-row>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row v-if="track.status === 'Completed' && track.finishingGrid.length > 0">
                    <v-col>
                        <v-card class="mx-auto" min-width="420">
                            <v-card-title>Starting grid <v-icon icon="mdi-ray-start-vertex-end" color="light-green-accent-3"></v-icon></v-card-title>
                            <v-row no-gutters>
                                <v-col> <!--Starting Grid left -->
                                    <v-list>
                                        <v-list-item v-for="slot in getOddGrid(track.startingGrid)" :key="slot.position" class="text-right">
                                            <v-list-item-title>
                                                <b>{{ slot.position }}</b> -
                                                <v-chip v-if="slot.qualifyingTime != null"
                                                        variant="outlined" color="blue-grey-darken-3" title="Qualification time">
                                                    {{ slot.qualifyingTime }} <v-icon end icon="mdi-timer-outline" color="green"></v-icon>
                                                </v-chip>
                                                <v-chip v-else
                                                        variant="elevated" color="deep-orange-darken-4">
                                                    DNF
                                                </v-chip>
                                                <v-avatar v-if="slot.driverDetails != null" :image="`${ slot.driverDetails.image }`" class="mx-1"></v-avatar>
                                                <v-avatar v-else><v-icon icon="mdi-account-circle" color="blue-grey-darken-3" size="x-large"></v-icon></v-avatar>
                                            </v-list-item-title>
                                            <v-list-item-subtitle v-if="slot.driverDetails != null">
                                                {{ slot.driverDetails.name }}
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle v-else>
                                                ?
                                            </v-list-item-subtitle>
                                            <v-divider></v-divider>
                                        </v-list-item>
                                    </v-list>
                                </v-col>
                                <v-col> <!--Starting Grid right -->
                                    <v-list class="pt-10">
                                        <v-list-item v-for="slot in getEvenGrid(track.startingGrid)" :key="slot.position" class="text-left">
                                            <v-list-item-title>
                                                <v-avatar v-if="slot.driverDetails != null" :image="`${ slot.driverDetails.image }`" class="mx-1"></v-avatar>
                                                <v-avatar v-else class="mx-1"><v-icon icon="mdi-account-circle" color="blue-grey-darken-3" size="x-large"></v-icon></v-avatar>
                                                <v-chip v-if="slot.qualifyingTime != null"
                                                        variant="outlined" color="blue-grey-darken-3" title="Qualification time">
                                                    <v-icon start icon="mdi-timer-outline" color="green"></v-icon> {{ slot.qualifyingTime }}
                                                </v-chip>
                                                <v-chip v-else
                                                        variant="elevated" color="deep-orange-darken-4">
                                                    DNF
                                                </v-chip>
                                                - <b>{{ slot.position }}</b>
                                            </v-list-item-title>
                                            <v-list-item-subtitle v-if="slot.driverDetails != null">
                                                {{ slot.driverDetails.name }}
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle v-else>
                                                ?
                                            </v-list-item-subtitle>
                                            <v-divider></v-divider>
                                        </v-list-item>
                                    </v-list>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col>
                        <v-card class="mx-auto" min-width="420">
                            <v-card-title>Final result <v-icon icon="mdi-history" color="pink-lighten-1"></v-icon></v-card-title>
                            <v-row no-gutters>
                                <v-col> <!--Final result left -->
                                    <v-list>
                                        <v-list-item v-for="slot in getOddGrid(track.finishingGrid)" :key="slot.position" class="text-right">
                                            <v-list-item-title>
                                                <b>{{ slot.position }}</b> -
                                                <v-chip v-if="slot.time != null && (slot.time.includes('.') || slot.time.includes('Lap') || slot.time.includes('+'))"
                                                        variant="outlined" color="blue-grey-darken-3" title="Finishing time">
                                                    {{ slot.time }} <v-icon end icon="mdi-timer-outline" color="green"></v-icon>
                                                </v-chip>
                                                <v-chip v-else-if="slot.time != null"
                                                        variant="elevated" color="deep-orange-darken-4">
                                                    {{ slot.time }}
                                                </v-chip>
                                                <v-chip v-else
                                                        variant="elevated" color="deep-orange-darken-4">
                                                    DNF
                                                </v-chip>
                                                <v-avatar v-if="slot.driverDetails != null" :image="`${ slot.driverDetails.image }`" class="mx-1"></v-avatar>
                                                <v-avatar v-else class="mx-1"><v-icon icon="mdi-account-circle" color="blue-grey-darken-3" size="x-large"></v-icon></v-avatar>
                                            </v-list-item-title>
                                            <v-list-item-subtitle v-if="slot.driverDetails != null">
                                                {{ slot.driverDetails.name }}
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle v-else>
                                                ?
                                            </v-list-item-subtitle>
                                            <v-divider></v-divider>
                                        </v-list-item>
                                    </v-list>
                                </v-col>
                                <v-col> <!--Final result right -->
                                    <v-list class="pt-10">
                                        <v-list-item v-for="slot in getEvenGrid(track.finishingGrid)" :key="slot.position" class="text-left">
                                            <v-list-item-title>
                                                <v-avatar v-if="slot.driverDetails != null" :image="`${ slot.driverDetails.image }`" class="mx-1"></v-avatar>
                                                <v-avatar v-else class="mx-1"><v-icon icon="mdi-account-circle" color="blue-grey-darken-3" size="x-large"></v-icon></v-avatar>
                                                <v-chip v-if="slot.time != null && (slot.time.includes('.') || slot.time.includes('Lap') || slot.time.includes('+'))"
                                                        variant="outlined" color="blue-grey-darken-3" title="Finishing time">
                                                    <v-icon start icon="mdi-timer-outline" color="green"></v-icon> {{ slot.time }}
                                                </v-chip>
                                                <v-chip v-else-if="slot.time != null"
                                                        variant="elevated" color="deep-orange-darken-4">
                                                    {{ slot.time }}
                                                </v-chip>
                                                <v-chip v-else
                                                        variant="elevated" color="deep-orange-darken-4">
                                                    DNF
                                                </v-chip>
                                                - <b>{{ slot.position }}</b>
                                            </v-list-item-title>
                                            <v-list-item-subtitle v-if="slot.driverDetails != null">
                                                {{ slot.driverDetails.name }}
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle v-else>
                                                ?
                                            </v-list-item-subtitle>
                                            <v-divider></v-divider>
                                        </v-list-item>
                                    </v-list>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-btn icon="mdi-chevron-up" @click="closePanel(track.raceId)" class="mt-2"></v-btn>
            </v-expansion-panel-text>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<style scoped>
    .v-progress-circular {
        margin: 1rem;
    }
</style>

<script lang="js">
    import { defineComponent, toRaw } from 'vue';

    export default defineComponent({
        props: { selectedyear: Number },
        components: {
        },
        data() {
            return {
                loading: false,
                seasoncircuits: null,
                panel: []
            }
        },
        watch: {
            '$route': 'fetchData',
            'selectedyear': 'fetchData'
        },
        created() {
            this.fetchData();
        },
        methods: {
            positionString(val) {
                if (val == 1) {
                    return `${val}st`;
                } else if (val == 2) {
                    return `${val}nd`;
                } else if (val == 3) {
                    return `${val}rd`;
                } else {
                    return `${val}th`;
                }
            },
            closePanel(val) { 
                this.panel = this.panel.filter(item => item != val);
            },
            getOddGrid(objArray) {
                objArray = toRaw(objArray);
                if (objArray.length > 0) {
                    return objArray.filter((item, index) => {
                        return index % 2 === 0;
                    });
                }
                return null;
            },
            getEvenGrid(objArray) {
                objArray = toRaw(objArray);
                if (objArray.length > 0) {
                    return objArray.filter((item, index) => {
                        return index % 2 !== 0;
                    });
                }
                return null;
            },
            fetchData() {
                this.seasoncircuits = null;
                this.loading = true;
                this.panel = [];
                fetch('/api/races/fullseason/' + this.selectedyear)
                    .then(r => r.json())
                    .then(json => {
                        this.seasoncircuits = json;
                        this.loading = false;
                        return;
                    });
            }
        },
    });
</script>