<template>
    <v-container class="bg-blue-grey-darken-3" style="max-width:1000px">
        <v-row align="center">
            <v-col><span class="text-h4">Driver Profiles</span></v-col>
        </v-row>
        <v-row align="center">
            <v-col>
                <div v-if="loading" class="loading">
                    <v-progress-circular indeterminate color="red-darken-1"></v-progress-circular>
                </div>
                <v-expansion-panels variant="accordion"
                                    multiple
                                    v-if="driverprofiles"
                                    v-model="panel">
                    <v-expansion-panel v-for="profile in driverprofiles" :key="profile.driverId" :value="`${ profile.driverId }`">
                        <v-expansion-panel-title>
                            <v-row align="center">
                                <v-col><v-avatar :image="`${ profile.image }`"></v-avatar></v-col>
                                <v-col><b>{{ profile.name }}</b></v-col>
                                <v-col>{{ profile.abbr }}</v-col>
                                <v-col><span :title="`${ profile.country }`" :class="`${ profile.countryCode }`"></span></v-col>
                                <v-col>
                                    <div v-if="profile.worldChampionships > 0">
                                        <v-icon icon="mdi-trophy" color="amber-darken-3">
                                        </v-icon> {{ profile.worldChampionships }}
                                    </div>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text class="bg-blue-grey-darken-1">
                            <v-card class="mx-auto" min-width="365">
                                <v-card-title>{{ profile.name }}</v-card-title>
                                <v-avatar size="120" :image="`${ profile.image }`"></v-avatar>
                                <v-card-subtitle class="pt-4">
                                    {{ profile.nationality }} <span :class="`${ profile.countryCode }`"></span>
                                </v-card-subtitle>
                                <v-card-text>
                                    <v-divider style="margin: 3px 0 3px 0"></v-divider>
                                    <v-row dense v-if="profile.country">
                                        <v-col class="font-weight-medium">Country</v-col>
                                        <v-col>{{ profile.country }}</v-col>
                                    </v-row>
                                    <v-row dense v-if="profile.abbr">
                                        <v-col class="font-weight-medium">Abbreviation</v-col>
                                        <v-col>{{ profile.abbr }}</v-col>
                                    </v-row>
                                    <v-row dense v-if="profile.birthdate">
                                        <v-col class="font-weight-medium">Birth date</v-col>
                                        <v-col>{{ profile.birthdate }}</v-col>
                                    </v-row>
                                    <v-row dense v-if="profile.age">
                                        <v-col class="font-weight-medium">Age</v-col>
                                        <v-col>{{ profile.age }}</v-col>
                                    </v-row>
                                    <v-divider style="margin: 3px 0 3px 0"></v-divider>
                                    <v-row>
                                        <v-col class="font-weight-medium text-h6">
                                            Career <v-icon icon="mdi-sitemap-outline" color="teal"></v-icon>
                                        </v-col>
                                    </v-row>
                                    <v-row dense v-if="profile.grandsPrixEntered">
                                        <v-col class="font-weight-medium">Total grand prix</v-col>
                                        <v-col>{{ profile.grandsPrixEntered }}</v-col>
                                    </v-row>
                                    <v-row dense v-if="profile.podiums">
                                        <v-col class="font-weight-medium">Podiums</v-col>
                                        <v-col>{{ profile.podiums }}</v-col>
                                    </v-row>
                                    <v-row dense v-if="profile.highestRaceFinish">
                                        <v-col class="font-weight-medium">Highest finish</v-col>
                                        <v-col>{{ positionString(profile.highestRaceFinish) }} - Total: {{ profile.highestRaceFinishTotal }}</v-col>
                                    </v-row>
                                    <v-row dense v-if="profile.careerPoints">
                                        <v-col class="font-weight-medium">Career points</v-col>
                                        <v-col>{{ profile.careerPoints }}</v-col>
                                    </v-row>
                                    <v-row dense v-if="profile.lastSeason">
                                        <v-col class="font-weight-medium">Last season</v-col>
                                        <v-col>{{ profile.lastSeason }}</v-col>
                                    </v-row>
                                    <v-divider style="margin: 3px 0 3px 0"></v-divider>
                                    <v-row>
                                        <v-col class="font-weight-medium text-h6">
                                            Team history <v-icon icon="mdi-playlist-edit" color="indigo-accent-4"></v-icon>
                                        </v-col>
                                    </v-row>
                                    <v-row v-for="team in profile.driverTeams" :key="team.season" dense>
                                        <v-col>{{ team.season }} - {{ team.teamName }}</v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                            <v-btn icon="mdi-chevron-up" @click="closePanel(profile.driverId)" class="mt-2"></v-btn>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="js">
    import { defineComponent } from 'vue';

    export default defineComponent({
        components: {
        },
        data() {
            return {
                loading: false,
                driverprofiles: null,
                panel: []
            }
        },
        watch: {
            '$route': 'fetchData'
        },
        created() {
            this.fetchData();
        },
        methods: {
            positionString(val) {
                if (val == 1) {
                    return `${ val }st`;
                } else if (val == 2) {
                    return `${ val }nd`;
                } else if (val == 3) {
                    return `${ val }rd`;
                } else {
                    return `${ val }th`;
                }
            },
            closePanel(val) {
                this.panel = this.panel.filter(item => item != val);
            },
            fetchData() {
                this.driverprofiles = null;
                this.loading = true;

                fetch('/api/driverstats/driverprofiles')
                    .then(r => r.json())
                    .then(json => {
                        this.driverprofiles = json;
                        this.loading = false;
                        return;
                    });
            }
        },
    });
</script>

<style>
</style>