<template>
    <v-app-bar class="bg-blue-grey-lighten-5"
               density="compact"
               scroll-behavior="collapse">

        <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <router-link title="Home" to="/">
            <FullLogo class="mt-4 ml-3" />
        </router-link>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer"
                         temporary>
        <v-list-item prepend-icon="mdi-home" title="Home" to="/"></v-list-item>
        <v-divider></v-divider>
        <v-list-item subtitle="MEMBERS" base-color="blue" density="comfortable" elevation="5"></v-list-item>
        <v-list-item prepend-icon="mdi-card-account-details" title="Driver Profiles" to="/members/driverprofiles"></v-list-item>
        <v-list-item prepend-icon="mdi-compare" title="Head to Head" to="/members/headtohead"></v-list-item>
        <v-divider></v-divider>
        <v-list-item subtitle="EXTRA" base-color="blue" density="comfortable"  elevation="5"></v-list-item>
        <v-list-item prepend-icon="mdi-twitter" title="X - Twitter" @click="goToTwitter"></v-list-item>
        <v-list-item :prepend-avatar="ssIcon" title="Syntactic Salt" @click="goToSyntacticSalt"></v-list-item>
        <v-list-item>
            <BuyMeACoffee />
        </v-list-item>
    </v-navigation-drawer>
</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import FullLogo from './logos/FullLogo.vue'
    import BuyMeACoffee from './Support/BuyMeACoffee.vue';

    export default defineComponent({
        components: {
            FullLogo,
            BuyMeACoffee
        },
        data() {
            return {
                ssIcon: require('./../assets/SyntacticSalt.png'),
                drawer: false,
                group: null
            }
        },
        watch: {
            group() {
                this.drawer = false
            },
        },
        methods: {
            goToTwitter() {
                window.open('https://twitter.com/intent/tweet?screen_name=ginoarpesella', '_blank');
            },
            goToSyntacticSalt() {
                window.open('https://www.syntacticsalt.net/', '_blank');
            }
        }
    });
</script>