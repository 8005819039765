<template>
    <v-container class="bg-blue-grey-darken-3">
        <v-row align="center">
            <v-col><span class="text-h4">Head to Head</span></v-col>
        </v-row>
        <v-row align="center">
            <v-col>
                <v-select v-if="driverSelection"
                          label="Select main driver"
                          :items="driverSelection"
                          v-model="selectedDriver"
                          return-object
                          loader="listLoading"
                          style="min-width:250px">
                </v-select>
            </v-col>
            <v-col>
                <v-select :disabled="teammateSelectDisabled"
                          label="Select teammate driver"
                          :items="teammateSelection"
                          v-model="selectedTeammate"
                          style="min-width:250px">
                </v-select>
            </v-col>
        </v-row>
        <v-row align="center">
            <v-col>
                <div v-if="headtoheadLoading" class="loading">
                    <v-progress-circular indeterminate color="red-darken-1"></v-progress-circular>
                </div>
                <div v-if="notEnoughData" class="text-h5">
                    <span v-if="headToHeadData.seasons.length === 0">{{notEnoughDataMsg[1]}}</span>
                    <span v-else>{{notEnoughDataMsg[0]}}</span>
                </div>
                <v-row v-if="headToHeadData && !notEnoughData" align="center">
                    <v-col>
                        <ScoringSystem />
                    </v-col>
                    <v-expansion-panels variant="accordion"
                                        multiple>
                        <v-expansion-panel v-for="h2h in headToHeadData.seasons" :key="h2h.season">
                            <v-expansion-panel-title>
                                <v-row align="center">
                                    <v-col><b>{{ h2h.season }}</b></v-col>
                                    <v-col>{{ h2h.teamName }}</v-col>
                                    <v-col><v-chip title="Head 2 head points" variant="outlined">{{ h2h.headToHeadPoints }}</v-chip></v-col>
                                </v-row>
                            </v-expansion-panel-title>
                            <v-expansion-panel-text class="bg-blue-grey-darken-1">
                                <v-row wrap>
                                    <v-col align="center">
                                        <span class="text-subtitle-1">Head to Head score</span>
                                        <br />
                                        <span class="pa-2 text-h3">
                                            <b>{{ h2h.headToHeadPoints }}</b>
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <HeadToHeadSeasonInfo :seasonInfo="h2h.mainDriver" :driverName="headToHeadData.driverName" :driverImage="headToHeadData.driverImage" />
                                    </v-col>
                                    <v-col>
                                        <HeadToHeadSeasonInfo :seasonInfo="h2h.teammate" :driverName="headToHeadData.teammateName" :driverImage="headToHeadData.teammateImage" />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <HeadToHeadStats :stats="h2h" />
                                    </v-col>
                                </v-row>
                                <v-row justify="center">
                                    <v-col align="center">
                                        <v-sheet style="max-width:615px" rounded>
                                            <div class="text-h6">Total distance gained</div>
                                            <p class="mx-2">Displays all comparable qualifying sessions and the total time gained or lost compared to their teammate.</p>
                                            <v-table style="max-width:600px">
                                                <thead>
                                                    <tr>
                                                        <th>Q1</th>
                                                        <th>Q2</th>
                                                        <th>Q3</th>
                                                    </tr>
                                                </thead>
                                                <tbody align="left">
                                                    <tr>
                                                        <td>{{ millisecondsToSeconds(h2h.q1Gap) }} sec</td>
                                                        <td>{{ millisecondsToSeconds(h2h.q2Gap) }} sec</td>
                                                        <td>{{ millisecondsToSeconds(h2h.q3Gap) }} sec</td>
                                                    </tr>
                                                </tbody>
                                            </v-table>
                                        </v-sheet>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-text>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import HeadToHeadSeasonInfo from './HeadToHeadSeasonInfo.vue';
    import HeadToHeadStats from './HeadToHeadStats.vue';
    import ScoringSystem from './../Dialogs/ScoringSystem.vue';

    export default defineComponent({
        components: {
            HeadToHeadSeasonInfo,
            HeadToHeadStats,
            ScoringSystem
        },
        data() {
            return {
                // loaders
                listLoading: true,
                headtoheadLoading: false,

                // dropdown selections
                selectedDriver: null,
                selectedTeammate: null,

                // data
                teammateSelection: [],
                driverSelection: null,
                headToHeadData: null,
                notEnoughData: false,
                notEnoughDataMsg: ["Not enough data to compare drivers.", "Missing Data."],

                teammateSelectDisabled: true,
                dialogPointsSystems: false
            }
        },
        watch: {
            '$route': 'fetchData',
            'selectedDriver': 'setTeammates',
            'selectedTeammate': 'loadHeadToHeadData'
        },
        created() {
            this.fetchData();
        },
        methods: {
            setTeammates(e) {
                this.selectedTeammate = null;
                this.teammateSelection = e.teammates;
                this.teammateSelectDisabled = false;
                this.headToHeadData = null;
            },
            millisecondsToSeconds(v) {
                return (v / 1000).toFixed(3);
            },
            hasEnoughData() {
                var totalCompareableQualifyingSessions = 0;
                for (var i = 0; i < this.headToHeadData.seasons.length; i++) {
                    totalCompareableQualifyingSessions += this.headToHeadData.seasons[i].numberOfQ1Compared;
                }

                if (totalCompareableQualifyingSessions < 3) {
                    this.notEnoughData = true;
                } else {
                    this.notEnoughData = false;
                }
            },
            loadHeadToHeadData(teammateId) {
                this.headToHeadData = null;
                var driverId = this.selectedDriver.value;

                if (driverId == null || teammateId == null) return;

                this.headtoheadLoading = true;

                fetch('/api/driverstats/headtohead/' + driverId + '/' + teammateId)
                    .then(r => r.json())
                    .then(json => {
                        this.headToHeadData = json;
                        this.headtoheadLoading = false;
                        this.hasEnoughData();
                        return;
                    });
            },
            fetchData() {
                this.driverSelection = null;
                this.listLoading = true;

                fetch('/api/driverstats/headtoheadselection')
                    .then(r => r.json())
                    .then(json => {
                        this.driverSelection = json;
                        this.listLoading = false;                        
                        return;
                    });
            }
        }
    });
</script>

<style>
</style>