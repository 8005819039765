<template>
    <v-card class="mx-auto" min-width="365">
        <v-card-title>
            <v-avatar size="60" :image="`${ driverImage }`"></v-avatar>
            <div class="text-h6"> {{ driverName }}</div>
        </v-card-title>
        <v-card-text>
            How many times a driver entered each qualifying session.
            <v-table>
                <thead>
                    <tr>
                        <th>Q1</th>
                        <th>Q2</th>
                        <th>Q3</th>
                    </tr>
                </thead>
                <tbody align="left">
                    <tr>
                        <td>{{ seasonInfo.totalQ1 }}</td>
                        <td>{{ seasonInfo.totalQ2 }}</td>
                        <td>{{ seasonInfo.totalQ3 }}</td>
                    </tr>
                </tbody>
            </v-table>
            <v-row class="pt-3">
                <v-col>Races started: <b>{{ seasonInfo.raceStarts }}</b></v-col>
                <v-col>Races finished: <b>{{ seasonInfo.raceFinishes }}</b></v-col>
                <v-col>Season points: <b>{{ seasonInfo.score }}</b></v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script lang="js">
    import { defineComponent } from 'vue';

    export default defineComponent({
        props: { seasonInfo : Object, driverName: String, driverImage: String },
        data() {
            return {
                
            }
        }
    });
</script>

<style>
</style>