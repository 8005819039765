<template>
    <v-container class="bg-blue-grey-darken-3">
        <v-row>
            <v-col>
                <v-alert closable
                         type="success"
                         border="start"
                         icon="mdi-crown-circle-outline"
                         variant="outlined"
                         density="compact"
                         text="Take a look at the members section while it's still free!">
                </v-alert>
            </v-col>
        </v-row>
        <v-row class="text-center">
            <v-spacer></v-spacer>
            <v-col>
                <v-select v-if="formulayears"
                          :loading="loading"
                          v-model="selectedyear"
                          label="Select formula year"
                          :items="formulayears"
                          style="min-width:250px">
                </v-select>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row>
            <v-col>
                <FullCircuitDetails :selectedyear="selectedyear" />
            </v-col>
        </v-row>
        <v-row>
            <v-col class="mx-auto">
                <DriverStandings :selectedyear="selectedyear" />
            </v-col>
            <v-col class="mx-auto">
                <TeamRankings :selectedyear="selectedyear" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import DriverStandings from './DriverStandings.vue'
    import TeamRankings from './TeamRankings.vue'
    import FullCircuitDetails from './FullCircuitDetails.vue'

    export default defineComponent({
        components: {
            DriverStandings,
            TeamRankings,
            FullCircuitDetails
        },
        data() {
            return {
                loading: true,
                formulayears: null,
                selectedyear: new Date().getFullYear()
            }
        },
        watch: {
            '$route': 'fetchData'
        },
        created() {
            this.fetchData();
        },
        methods: {
            fetchData() {
                this.formulayears = null;
                this.loading = true;

                fetch('/api/races/allraceyears')
                    .then(r => r.json())
                    .then(json => {
                        this.formulayears = json;
                        this.loading = false;
                        return;
                    });
            }
        }
    });
</script>