import { createRouter, createWebHistory } from "vue-router";
import LandingPage from "../components/LandingPage/LandingPage.vue";
import DriverProfiles from "../components/Members/DriverProfiles.vue";
import HeadToHead from "../components/Members/HeadToHead.vue";

const routes = [
    { path: "/", name: "Home", component: LandingPage },
    { path: "/members/driverprofiles", name: "Driver Profiles", component: DriverProfiles },
    { path: "/members/headtohead", name: "Head to Head", component: HeadToHead }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;