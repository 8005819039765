<template>
    <v-row>
        <v-col align="center">
            <v-sheet 
                     border 
                     rounded
                     class="pa-4"
                     style="max-width: 750px">
                <VueUiVerticalBar ref="verticalBarChart"
                                  :config="config"
                                  :dataset="dataset" />
            </v-sheet>
        </v-col>
    </v-row>
</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import { VueUiVerticalBar } from 'vue-data-ui';

    export default defineComponent({
        props: { stats : Object },
        components: {
            VueUiVerticalBar
        },
        data() {
            return {
                dataset: this.createDataset(),
                config: {
                    style: {
                        chart: {
                            layout: {
                                bars: {
                                    dataLabels: {
                                        percentage: { show: false }
                                    },
                                    parentLabels: { show: false }
                                }
                            },
                            legend: { show: false },
                            tooltip: { showPercentage: false },
                            title: {
                                text: "Season performance",
                                subtitle: {
                                    text: "This graph shows when the selected driver outperformed their teammate. The values are comparable times only. For exmaple, if driver A set a time for qualifying 1, 2 and 3 but driver B only set times for qualifying 1 and 2, then we can only compare the two drivers for qualifying 1 and 2. Qualifying 3 with be ignored."
                                }
                            }
                        },
                    },
                    userOptions: {
                        show: false
                    }
                }
            }
        },
        methods: {
            createDataset() {
                return [
                {
                    name: "",
                    value: 4,
                    children: [
                        {
                            name: "1st Qualifying",
                            value: this.stats.numberOfQ1Compared
                        },
                        {
                            name: "Out qualified",
                            value: this.stats.outQualifiedQ1
                        }
                    ]

                }, {
                    name: "",
                    value: 3,
                    children: [
                        {
                            name: "2nd Qualifying",
                            value: this.stats.numberOfQ2Compared
                        },
                        {
                            name: "Out qualified",
                            value: this.stats.outQualifiedQ2
                        }
                    ]

                }, {
                    name: "",
                    value: 2,
                    children: [
                        {
                            name: "3rd Qualifying",
                            value: this.stats.numberOfQ3Compared
                        },
                        {
                            name: "Out qualified",
                            value: this.stats.outQualifiedQ3
                        }
                    ]
                }, {
                    name: "",
                    value: 1,
                    children: [
                        {
                            name: "Race",
                            value: this.stats.numberOfRacesCompared
                        },
                        {
                            name: "Finished ahead",
                            value: this.stats.raceHeadToHeadWins
                        }
                    ]
                }];
            }
        }
    });
</script>

<style>
</style>