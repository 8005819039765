import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import 'vuetify/dist/vuetify.min.css'
import router from './router'

// charts
import "vue-data-ui/style.css"

const app = createApp(App)
app.use(vuetify)
app.use(router)
app.mount('#app')
