<template>
    <v-btn variant="elevated" density="compact" color="light-green-darken-1">
        Scoring sytem info
        <v-dialog v-model="dialogPointsSystems"
                  activator="parent"
                  width="auto">
            <v-card>
                <v-card-title>
                    Scoring System
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col>
                            A driver can gain points by outperforming their teammate in qualifying and the race itself.<br />
                            If a driver <b>fails</b> to outperform their teammate, <b>negative</b> points are gained.<br />
                            This will create a larger divide and could leave a driver with a negative score at the end of the season.<br />
                        </v-col>
                    </v-row>
                    <v-row class="align-center">
                        <v-col class="ml-5">
                            The points are calculated as follows:
                            <v-list>
                                <v-list-item>
                                    Qualifying 1
                                    <v-list-item-subtitle>1 point</v-list-item-subtitle>
                                </v-list-item>
                                <v-list-item>
                                    Qualifying 2
                                    <v-list-item-subtitle>2 point</v-list-item-subtitle>
                                </v-list-item>
                                <v-list-item>
                                    Qualifying 3
                                    <v-list-item-subtitle>3 point</v-list-item-subtitle>
                                </v-list-item>
                                <v-list-item>
                                    Race
                                    <v-list-item-subtitle>7 point</v-list-item-subtitle>
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-alert type="info"
                                     border="start"
                                     variant="outlined"
                                     density="compact"
                                     text="Sprint races have been excluded from the scoring">
                            </v-alert>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-btn block @click="dialogPointsSystems = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-btn>
</template>

<script lang="js">
    import { defineComponent } from 'vue';

    export default defineComponent({
        components: {
        },
        data() {
            return {
                dialogPointsSystems: false
            }
        }
    });
</script>