<template>
    <div v-if="loading" class="text-center">
        <v-progress-circular indeterminate color="red-darken-1"></v-progress-circular>
    </div>
    <span class="text-h4">Team Rankings</span>
    <v-sheet :elevation="4"
             border
             rounded
             v-if="teamrankings"
             style="min-width:500px">
        <v-table v-if="teamrankings.length > 0">
            <thead>
                <tr>
                    <th class="text-left"><strong>#</strong></th>
                    <th class="text-center"><strong>Logo</strong></th>
                    <th class="text-center">Name</th>
                    <th class="text-left"><strong>Points</strong></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="team in teamrankings" :key="team.position">
                    <td><b>{{ team.position }}</b></td>
                    <td><v-img :width="120" aspect-ratio="16/9" :src="`${ team.teamLogo}`"></v-img></td>
                    <td>{{ team.teamName }}</td>
                    <td class="font-weight-medium">{{ team.points }}</td>
                </tr>
            </tbody>
        </v-table>
        <span v-else class="text-h5">No Data</span>
    </v-sheet>
</template>

<script lang="js">
    import { defineComponent, ref } from 'vue';

    export default defineComponent({
        props: { selectedyear: Number },
        components: {
        },
        data() {
            return {
                loading: false,
                teamrankings: null
            }
        },
        setup() {
            const activeItem = ref('flush-collapseOne');
            return {
                activeItem
            }
        },
        watch: {
            '$route': 'fetchData',
            'selectedyear': 'fetchData'
        },
        created() {
            this.fetchData();
        },
        methods: {
            fetchData() {
                this.teamrankings = null;
                this.loading = true;

                fetch('/api/teams/teamrankings/' + this.selectedyear)
                    .then(r => r.json())
                    .then(json => {
                        this.teamrankings = json;
                        this.loading = false;
                        return;
                    });
            }
        },
    });
</script>