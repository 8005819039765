<template>
    <v-layout>
        <MainNavbar />
        <v-main>
            <router-view />
        </v-main>
    </v-layout>
</template>

<script>
    import MainNavbar from './components/MainNavbar.vue'

    export default {
        name: 'App',
        components: {
            MainNavbar
        }
    }
</script>

<style>
    @import './css/main.css';
    @import './css/alltheflags.css';
</style>
